/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-25",
    versionChannel: "nightly",
    buildDate: "2024-08-25T00:06:04.595Z",
    commitHash: "6fca7444b6b0be3157e1de9f24aa928ec969bd50",
};
